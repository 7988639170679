@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  svg {
    @apply inline-block;
  }

  img,
  video {
    @apply max-w-none block;
  }
}

@layer components {
  .container {
    @apply mx-auto max-w-none w-full px-16 desktop:max-w-[80rem] desktop:px-32 desktopLarge:max-w-[98rem]
  }
}

@layer utilities {
  .picture_white_overlay {
    background: -moz-radial-gradient(
      transparent 70%,
      rgba(250, 250, 251, 0.5) 50px
    );
    background: radial-gradient(transparent 70%, rgba(250, 250, 251, 0.5) 50px);
    background: -ms-radial-gradient(
      transparent 70%,
      rgba(250, 250, 251, 0.5) 50px);
    background: -o-radial-gradient(
      transparent 70%,
      rgba(250, 250, 251, 0.5) 50px);
    @apply w-[220px] h-[220px] absolute
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #ff6500;
  -webkit-box-shadow: 0 0 0px 1000px transition inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
